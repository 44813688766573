import React from 'react'
import PropTypes from 'prop-types'

const CardRow = ({ rowItems, className }) => (
  <div className={`columns is-centered ${className}`}>
    {rowItems.map(item => (
      <div
        key={item.text}
        className={
          rowItems.length > 1
            ? 'column'
            : 'column is-centered is-flex-none-mobile is-half-tablet is-two-fifths-desktop'
        }
      >
        <div className="banner-card">
          <div className="banner-card__background">
            <h2 className="banner-card__headline">{item.headline}</h2>
            {item.text && item.text.length > 0 && (
              <p className="banner-card__text">{item.text}</p>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
)

CardRow.propTypes = {
  rowItems: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  className: PropTypes.string,
}

export default CardRow
