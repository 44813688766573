import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import ArticleList from '../components/ArticleList'
import BannerCards from '../components/BannerCards'
import Slugify from '../utils/slugify'

class TagRoute extends React.Component {
  render() {
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const group = this.props.data.allMarkdownRemark.edges
    const tagGroup = this.props.data.allMarkdownRemark.group
    const recentPosts = this.props.data.recentposts.edges
    const bannerinfo = {
      cards: [
        {
          headline: 'News',
          text: '',
        },
      ],
    }
    const bgimage = this.props.data.desktop.childImageSharp.fluid

    return (
      <Layout>
        <div className="">
          <div className="banner-image--container banner-image--container--short">
            <Img
              className="full-width-image margin-top-0 banner-image banner-image--shorter"
              fluid={bgimage}
              backgroundColor={`#1d3d6d`}
            />
          </div>

          <div className="content-background">
            <div className="banner-card__container banner-card__container--short">
              <BannerCards rowItems={bannerinfo.cards} className={''} />
            </div>

            <section className="section section--short section--tags">
              <Helmet title={`Tag: ${tag} | ${title}`} />
              <div className="container">
                <div className="" style={{ marginBottom: '6rem' }}>
                  <nav
                    className="tag has-text-weight-bold is-radiusless breadcrumb has-bullet-separator is-large"
                    aria-label="breadcrumbs"
                  >
                    <ul>
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li className="is-active">
                        <Link
                          to={`/tags/${Slugify(tag)}`}
                        >{`Tag: "${tag}"`}</Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="columns is-desktop">
                    <div className="column is-8-desktop is-12-mobile">
                      <ArticleList posts={group} />
                    </div>
                    <div className="column is-4-desktop is-12-mobile news-sidebar">
                      <div className="">
                        <h3 className="news-sidebar__heading">Latest News</h3>
                        {recentPosts.map(post => {
                          return (
                            <div className="card mini-card" key={post.node.id}>
                              <div className="card-content">
                                <div className="mini-card__title is-size-5">
                                  <a
                                    className=""
                                    href={`${post.node.fields.slug}`}
                                  >
                                    {post.node.frontmatter.title}
                                  </a>
                                </div>
                                <div className="mini-card__tags is-size-6 card-content__tag">
                                  {post.node.frontmatter.tags.map(tag => (
                                    <span key={tag}>
                                      <a href={`/tags/${Slugify(tag)}`}>
                                        {tag}
                                      </a>
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="">
                        <h3 className="news-sidebar__heading">Tags</h3>
                        <div className="card mini-card">
                          <div className="card-content card-content--list">
                            {tagGroup.map(item => {
                              return (
                                <div
                                  className="card-content__tag"
                                  key={item.fieldValue}
                                >
                                  <a
                                    className=""
                                    href={`/tags/${Slugify(item.fieldValue)}`}
                                  >
                                    {item.fieldValue} ({item.totalCount})
                                  </a>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    desktop: file(relativePath: { eq: "news-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            tags
          }
        }
      }
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    recentposts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
      filter: { frontmatter: { templateKey: { eq: "news-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            tags
          }
        }
      }
    }
  }
`
