import React from 'react'
import { Link } from 'gatsby'
import logo from '../../static/img/logo.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <svg
          viewBox="0 0 1920 158"
          preserveAspectRatio="xMinYMin slice"
          xlink="http://www.w3.org/1999/xlink"
        >
          <mask id="m" fill="#fff">
            <rect id="r" width="100%" height="100%" />
            <path
              id="path2"
              fill="#000"
              d="M0,119.5 h145.75 q9,0 9,9 v20.5 q0,9 9,9 h282 q9,0 9,-9 v-20.5 q0,-9 9,-9 h1456.25 v39 h-1920 z"
            />
          </mask>
          <use href="#r" fill="rgba(26, 60, 111, .95)" mask="url(#m)" />
          <a href="/">
            <image href={logo} x="126" y="0" height="158px" width="355.5px" />
          </a>
        </svg>

        <div className="container navbar-container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img
                className="logo"
                src={logo}
                alt="Innovative Technology Solutions JV, LLC logo"
              />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/" activeClassName="is-active">
                Home
              </Link>
              {/* <Link
                className="navbar-item"
                to="/contract-vehicles"
                activeClassName="is-active"
              >
                Contract Vehicles
              </Link> */}
              <Link
                className="navbar-item"
                to="/news"
                activeClassName="is-active"
              >
                News
              </Link>
              <Link
                className="navbar-item"
                to="/contact"
                activeClassName="is-active"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
