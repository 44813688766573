import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCheckSquare,
  faPhoneAlt,
  faCalendarAlt,
  faEnvelope,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fab,
  faCheckSquare,
  faCalendarAlt,
  faPhoneAlt,
  faEnvelope,
  faChevronUp
)

const TemplateWrapper = ({ children }) => {
  return (
    <div>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/icons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/icons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/icons/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/icons/safari-pinned-tab.svg"
          color="#ff4400"
        />

        <link rel="manifest" href="/img/icons/site.webmanifest" />

        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.node,
}

export default TemplateWrapper
