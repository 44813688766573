import React from 'react'
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slugify from '../utils/slugify'

const PostCard = ({ posts }) => {
  return (
    <div className="">
      {posts &&
        posts
          .filter(post => post.node.frontmatter.templateKey === 'news-post')
          .map(({ node: post }) => (
            <article className="card" key={post.id}>
              {post.frontmatter.featuredimage ? (
                <a href={post.fields.slug} className="card-image">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.title}`,
                    }}
                  />
                </a>
              ) : null}
              <div className="card-content">
                <div className="card-content__tag">
                  {post.frontmatter.tags.map(tag => (
                    <span className="has-text-weight-bold" key={tag}>
                      <a href={`/tags/${Slugify(tag)}`}>{tag}</a>
                    </span>
                  ))}
                </div>

                <div className="media-content">
                  <h3 className="card-content__title">
                    <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                  </h3>
                  <div className="card-content__date">
                    <FontAwesomeIcon
                      icon="calendar-alt"
                      size="sm"
                      className="m-r-5"
                    />
                    <span className="is-size-7">{post.frontmatter.date}</span>
                  </div>
                </div>

                <div className="card-content__text">{post.excerpt}</div>

                <div>
                  <a
                    href={post.fields.slug}
                    className="button is-medium is-fullwidth is-radiusless is-uppercase"
                    style={{
                      maxWidth: '40%',
                    }}
                  >
                    Read More
                  </a>
                </div>
              </div>{' '}
              {/* /.card-content */}
            </article>
          ))}
    </div>
  )
}

export default PostCard
