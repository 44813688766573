import React from 'react'
import BackToTop from './BackToTop'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <BackToTop />
        <div className="columns">
          <div className="column is-vcentered">
            <div className="content has-text-centered">
              <p>
                Copyright &copy; 2019 Innovative Technology Solutions JV, LLC
              </p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
