import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { animateScroll as scroll } from 'react-scroll'

const BackToTop = () => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      window.onscroll = toggleVisibility
    }
  })

  function scrollUp() {
    scroll.scrollToTop({
      duration: 1250,
      delay: 25,
      smooth: 'easeInOutQuint',
    })
  }

  function toggleVisibility() {
    var html = document.getElementsByTagName('html')[0]
    var body = document.body
    var btn = document.getElementsByClassName('back-to-top')[0]

    // Height to should show/hide the button
    var pixelHeight = 200

    // IE11
    var top = document.documentElement.scrollTop || document.body.scrollTop

    if (
      top > pixelHeight ||
      html.scrollTop > pixelHeight ||
      body.scrollTop > pixelHeight
    ) {
      btn.classList.add('show-fade-in')
    } else {
      btn.classList.remove('show-fade-in')
    }
  }

  return (
    <div>
      <span className="back-to-top" onClick={scrollUp}>
        <FontAwesomeIcon size="lg" icon="chevron-up" />
      </span>
    </div>
  )
}

export default BackToTop
